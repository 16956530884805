import {Team} from '../../graphql-types';
import {AccessibleLanguageField} from '../../interfaces/languages';

export const teamLevelsOrder = (filterArray: Team[]) => {
    // TODO: handle different way
    const properOptionsOrder: Team[] = [];

    const global = filterArray.filter(data => data.level === 'global' && data.name === 'Global');
    const restGlobal = filterArray.filter(data => data.level === 'global' && data.name !== 'Global');
    const regions = filterArray.filter(item => item.level === 'region');

    properOptionsOrder.push(...global);

    regions.forEach(item => {
        properOptionsOrder.push(item);

        if (!Array.isArray(item.children)) {
            return;
        }

        const childrenIDs = item.children.map(child => child?.id);

        filterArray.forEach(team => {
            if (childrenIDs.includes(team.id)) {
                properOptionsOrder.push(team);
            }
        });
    });

    properOptionsOrder.push(...restGlobal);

    return properOptionsOrder;
};

export const getTeamsAccessibleLanguages = (
    teamsData: Team[],
    selectedTeam: string,
) => teamsData.find(team => team.id === selectedTeam)?.accessibleLanguages;

export const getHiddenLanguages = (
    selectedLanguages: AccessibleLanguageField[],
) => {
    const uncheckedLanguages = selectedLanguages
        .filter((lang: AccessibleLanguageField) => lang.checked === false)
        .map((lang: AccessibleLanguageField) => lang.value);

    return uncheckedLanguages;
};

export const getDefaultAccessibleLanguages = (
    teamsData: Team[],
    selectedTeam: string,
    hiddenLanguages: {[key in string]: any}[],
) => {
    const teamAccessibleLanguages = teamsData.find(team => team.id === selectedTeam)?.accessibleLanguages;

    if (!teamAccessibleLanguages) {
        return [];
    }

    const newAccessibleLanguages = teamAccessibleLanguages.map(lang => {
        if (!lang) {
            return {};
        }

        const getCheckedLanguageState = () => {
            const selectedLanguage = hiddenLanguages.find(language => language.id === lang.id);

            if (selectedLanguage) {
                return false;
            }

            if (!selectedLanguage) {
                return true;
            }

            return false;
        };

        return ({
            value: lang.id,
            id: lang.id,
            label: lang.name,
            checked: getCheckedLanguageState(),
        });
    });

    return newAccessibleLanguages;
};

export const getNewAccessibleLanguages = (
    teamsData: Team[],
    selectedTeam: string,
    selectedLanguages: AccessibleLanguageField[],
) => {
    const teamAccessibleLanguages = teamsData.find(team => team.id === selectedTeam)?.accessibleLanguages;

    if (!teamAccessibleLanguages) {
        return [];
    }

    const newAccessibleLanguages = teamAccessibleLanguages.map(lang => {
        if (!lang) {
            return {};
        }

        const getCheckedLanguageState = () => {
            const selectedLanguage = selectedLanguages.find((language: AccessibleLanguageField) => language.value === lang.id);

            if (selectedLanguage?.checked) {
                return false;
            }

            if (!selectedLanguage) {
                return true;
            }

            return false;
        };

        return ({
            value: lang.id,
            id: lang.id,
            label: lang.name,
            checked: getCheckedLanguageState(),
        });
    });

    return newAccessibleLanguages;
};
